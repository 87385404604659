<template>
  <header class="">
    <div class="max-w-screen-xl mx-auto p-1 flex">
      <router-link :to="{ name: 'CategoryIndex' }">
        <img src="@/images/logo.png" alt="Từ vựng tiếng Nhật chuyên ngành IT - tiengnhatit.com" class="w-[128px] max-w-[128px]" />
      </router-link>
      <div id="adsenseHeader" class="text-left w-full ml-4 flex items-end">
        <AdsenseHeader />
      </div>
    </div>

    <div class="bg-primary">
      <nav class="max-w-screen-xl mx-auto text-white p-2 flex justify-between">
        <div class="flex w-full items-left items-center justify-between space-x-2">
          <div class="pb-0 lg:pb-1">
            <router-link :to="{ name: 'CategoryIndex' }" class="text-xl lg:text-2xl">TiengNhatIT.com</router-link>
          </div>

          <div class="block lg:hidden">
            <button data-drawer-target="sidebar" data-drawer-toggle="sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" class="text-white text-2xl">
              <i class="fa-solid fa-bars"></i>
            </button>
          </div>

          <div
            class="hidden lg:flex flex-col lg:flex-row flex-grow justify-between space-y-2 lg:space-y-0 mt-4 lg:mt-0"
          >
            <div class="flex items-center space-x-2 justify-between">
              <router-link :to="{ name: 'CategoryIndex' }" class="text-md hover:bg-primary-dark transition p-2 rounded">
                <i class="fas fa-home" />
                <span>Trang chủ</span>
              </router-link>
              <router-link :to="{ name: 'KanjiRadicalIndex' }" class="text-md hover:bg-primary-dark transition p-2 rounded">
                <i class="fa-solid fa-language"></i>
                <span>Kanji Flashcard</span>
              </router-link>
            </div>

            <div class="flex flex-col lg:flex-row items-left lg:items-center lg:space-x-2">
              <div class="w-full lg:w-52">
                <KanjiSearchForm v-if="route.path.includes('/kanji/')"/>
                <SearchForm v-else />
              </div>
              <router-link :to="{ name: 'About' }" class="hover:bg-primary-dark transition p-2 rounded">
                <i class="fas fa-file-alt" />
                {{ $t('menu.about') }}
              </router-link>
              <router-link :to="{ name: 'Feedback' }" class="hover:bg-primary-dark transition p-2 rounded">
                <i class="fas fa-envelope" />
                {{ $t('menu.feedback') }}
              </router-link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import SearchForm from '&/SearchForm.vue'
import AdsenseHeader from '&/AdsenseHeader.vue'
import KanjiSearchForm from "&/KanjiSearchForm.vue";

const route = useRoute()
</script>
