<template>
  <AppFlashMessage />

  <div class="h-screen flex flex-col">
    <TheHeader @change-locale="setLocale" />

    <div class="flex-grow max-w-screen-xl mx-auto w-full">
      <router-view />
    </div>

    <TheFooter />
  </div>
  <TheAsideMenu/>
</template>

<script setup lang="ts">
import {nextTick, onBeforeMount, onMounted} from 'vue'
import { useI18n } from "vue-i18n";
import { useFlowbite } from "$/composables/useFlowbite";
import AppFlashMessage from '&/AppFlashMessage.vue'
import TheHeader from '&/TheHeader.vue'
import TheFooter from '&/TheFooter.vue'
import TheAsideMenu from "&/TheAsideMenu.vue";

const i18n = useI18n()

onBeforeMount(() => {
  getLocale()
})

onMounted(async () => {
  await nextTick()
  useFlowbite(['drawers']);
})

const getLocale = function() {
  const locale = localStorage.getItem('locale') // Get locale from local store
  if (locale) {
    i18n.locale.value = locale // If present then set i18n locale
  } else if (navigator.language.substring(0, 2) === 'vi') {
    i18n.locale.value = 'vi' // If locale is not present, set locale to 'vi' if navigator language is Vietnamese
  }
}

// Set i18n locale, then save locale to local storage
const setLocale = function(locale) {
  i18n.locale.value = locale
  localStorage.setItem('locale', locale)
}
</script>
