<template>
  <div
    class="kanji-card flex transition hover:cursor-pointer hover:opacity-70 hover:bg-blue-50"
    @click="router.push({ name: 'KanjiRadicalShow', params: { character: kanjiRadical.character }})"
  >
    <div class="kanji-card__clip"></div>

    <div class="kanji-card__draw-section">
      <div
        class="kanji-card__character"
      >
        {{ kanjiRadical.character }}
      </div>
      <div
        v-if="kanjiRadical.variant"
        class="kanji-card__variant"
      >
        ({{ kanjiRadical.variant }})
      </div>
    </div>

    <div class="kanji-card__text-section">
      <div
        class="kanji-card__kanji_count"
      >
        {{ kanjiRadical.kanji_count }} Kanji
      </div>

      <div class="kanji-card__name_vi">
        {{ kanjiRadical.name_vi }}
      </div>

      <div
        v-if="kanjiRadical.description_vi"
        class="kanji-card__description_vi"
      >
        {{ kanjiRadical.description_vi }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";

const router = useRouter()

defineProps<{
  kanjiRadical: object
}>()
</script>

<style scoped lang="sass">
.kanji-card
  @include stackedPapers()
</style>
