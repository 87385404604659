<template>
  <div v-if="wordsReady" class="grid grid-cols-12 gap-8">
    <main class="col-span-12 lg:col-span-9">
      <div id="mainContent">
        <SearchForm class="bg-white p-2 sticky top-0 shadow-md block lg:hidden" />
        <router-view :key="$route.fullPath" />
      </div>
      <PleaseDisableAdblock id="pleaseDisableAdblock" class="hidden" />
    </main>

    <aside class="hidden lg:block lg:col-span-3">
      <TheAside />
    </aside>
  </div>

  <AppLoading v-else class="mt-24"/>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useWordStore } from '$/stores/wordStore'
import SearchForm from '&/SearchForm.vue'
import TheAside from '&/TheAside.vue'
import PleaseDisableAdblock from '&/PleaseDisableAdblock.vue'
import AppLoading from "&/AppLoading.vue";

const wordStore = useWordStore()
const wordsReady = ref(false)

onMounted(async () => {
  await wordStore.fetchWords()
  await wordStore.fetchCategories()
  wordsReady.value = true
})
</script>
