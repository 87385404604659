<template>
  <nav class="flex breadcrumb mb-8" aria-label="Breadcrumb">
    <ol class="inline-flex flex-wrap items-center rtl:space-x-reverse">
      <li class="">
        <router-link :to="{ name: 'CategoryIndex'}">
          <i class="fa-solid fa-house"></i>
          <span>Trang chủ</span>
        </router-link>
      </li>
      <slot/>
    </ol>
  </nav>
</template>

<style scoped lang="sass">
.breadcrumb
  ol
    :deep(li)
      display: inline-flex
      align-items: center
      a
        color: $color-primary
        &:hover
          text-decoration: underline
      &:after
        display: flex
        align-items: center
        content: "\203A"
        font-size: 1.5rem
        margin: 0 0.5rem
        color: gray
      &:last-of-type
        &:after
          content: ''
</style>
