import { createRouter, createWebHistory } from 'vue-router'

import WordContainer from '&/WordContainer.vue'
import CategoryIndex from '&/CategoryIndex.vue'
import CategoryShow from '&/CategoryShow.vue'
import WordShow from '&/WordShow.vue'
import SearchResult from '&/SearchResult.vue'

import KanjiContainer from '&/KanjiContainer.vue'
import KanjiRadicalIndex from '&/KanjiRadicalIndex.vue'
import KanjiShow from '&/KanjiShow.vue'
import KanjiCollection from '&/KanjiCollection.vue'

import AppAbout from '&/AppAbout.vue'
import FeedbackNew from '&/FeedbackNew.vue'

const routes = [
    {
        path: '/',
        name: 'WordContainer',
        component: WordContainer,
        children: [
            {
                path: '/',
                name: 'CategoryIndex',
                component: CategoryIndex
            },
            {
                path: '/tu-vung-theo-chu-de/:slug',
                name: 'CategoryShow',
                component: CategoryShow,
                props: (route) => ({slug: route.params.slug})
            },
            {
                path: '/tim-kiem/:keyword',
                name: 'SearchResult',
                component: SearchResult
            },
            {
                path: '/tu-vung/:category_slug/:word',
                name: 'WordShow',
                component: WordShow,
                props: (route) => ({category_slug: route.params.category_slug, word: route.params.word})
            },
        ],
    },
    {
        path: '/kanji',
        name: 'KanjiContainer',
        component: KanjiContainer,
        children: [
            {
                path: '214-bo-thu',
                name: 'KanjiRadicalIndex',
                component: KanjiRadicalIndex,
            },
            {
                path: '214-bo-thu/:character',
                name: 'KanjiRadicalShow',
                component: KanjiCollection,
                props: (route) => ({character: route.params.character})
            },
            {
                path: 'jlpt-:jlpt_level',
                name: 'KanjiCollection',
                component: KanjiCollection,
                props: (route) => ({jlpt_level: route.params.jlpt_level})
            },
            {
                path: ':character',
                name: 'KanjiShow',
                component: KanjiShow,
                props: (route) => ({character: route.params.character})
            },
        ],
    },
    {
        path: '/thong-tin',
        name: 'About',
        component: AppAbout
    },
    {
        path: '/phan-hoi',
        name: 'Feedback',
        component: FeedbackNew
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
