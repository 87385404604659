export function highlightText(text, highlight) {
    return text.replace(
        new RegExp(highlight, "g"),
        `<strong>${highlight}</strong>`
    );
}

export function highlightKanjiReading(reading) {
    return reading.split('、').map(entry =>
        entry.split('-').map((part, index) => {
            if (index === 0) {
                return `<strong>${part}</strong>`;
            }
            return part;
        }).join('-')
    ).join('　');
}
