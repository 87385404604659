import { defineStore } from "pinia";
import apiCall from '$/plugins/axios'

export const useKanjiStore = defineStore('kanjiStore', {
    state: () => {
        return {
            kanjiRadicals: [],
            kanjiRadical: null,
            kanji: [],
            kanjiDetail: [],
            searchedKanji: [],
        }
    },

    actions: {
        async fetchKanjiRadicals() {
            try {
                const res = await apiCall.get('/kanji_radicals')
                this.kanjiRadicals = res.data
            } catch(err) {
                console.error('Failed to fetch kanji radicals:', err.message || err)
            }
        },

        async fetchKanjiRadical(character) {
            try {
                const res = await apiCall.get(`/kanji_radicals/${character}`)
                this.kanjiRadical = res.data
            } catch(err) {
                console.error('Failed to fetch kanji radical:', err.message || err)
            }
        },

        async fetchKanji() {
            try {
                const res = await apiCall.get('/kanji')
                this.kanji = res.data
            } catch(err) {
                console.error('Failed to fetch kanji:', err.message || err)
            }
        },

        async fetchKanjiDetail(character) {
            try {
                const res = await apiCall.get(`/kanji/${character}`)
                this.kanjiDetail = res.data
            } catch(err) {
                console.error('Failed to fetch kanji detail:', err.message || err)
            }
        }
    },

    getters: {
        kanjiRadicalCount: (state) => state.kanjiRadicals.length,
        kanjiCount: (state) => state.kanji.length,
    }
})
