<template>
  <form @submit.prevent class="">
    <div class="relative">
      <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
        </svg>
      </div>
      <input
        ref="inputField"
        v-model="keyword"
        type="search"
        placeholder="Tìm kiếm kanji"
        class="input-field search-field pl-8"
        @keydown="handleKeydown"
      />
    </div>

    <div class="relative search-dropdown">
      <div
        v-show="showDropdown"
        class="absolute top-0 right-0 z-30 bg-white text-black shadow-xl rounded-lg w-full lg:w-[500px] max-h-[60vh] overflow-y-scroll"
      >
        <router-link
          v-for="(kanji, index) in searchedKanji"
          :key="index"
          :to="{ name: 'KanjiShow', params: { character: kanji.character }}"
          class="flex space-x-2 justify-between p-2 border-b border-solid border-zinc-200 hover:bg-zinc-100"
          :class="{ 'selected-item': index === selectedIndex }"
          @click="showDropdown = false"
        >
          <div>
            <div class="flex space-x-2 relative">
              <div>
                <div class="p-2 bg-amber-100 font-kanji text-6xl">{{ kanji.character }}</div>
              </div>
              <div>
                <div class="flex flex-wrap space-x-2">
                  <div class="font-vietnamese text-2xl">{{ kanji.name_vi }}</div>
                  <div v-if="kanji.description_vi">({{ kanji.description_vi }})</div>
                </div>
                <div>{{ kanji.on_yomi }}</div>
                <div>{{ kanji.kun_yomi }}</div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import { useKanjiStore } from '$/stores/kanjiStore'

const router = useRouter()
const kanjiStore = useKanjiStore()
const keyword = ref(null)
const inputField = ref(null)
const selectedIndex = ref(-1)

const showDropdown = ref(false)
const searchedKanji = computed(() => {
  if (keyword.value) {
    const lowerKeyword = keyword.value.toLowerCase();
    return kanjiStore.kanji.filter((kanji) => {
      return (
        (kanji.character || '').indexOf(lowerKeyword) !== -1 ||
        (kanji.old_character || '').indexOf(lowerKeyword) !== -1 ||
        (kanji.name_vi || '').indexOf(lowerKeyword) !== -1 ||
        (kanji.description_vi || '').indexOf(lowerKeyword) !== -1 ||
        (kanji.on_yomi || '').indexOf(lowerKeyword) !== -1 ||
        (kanji.on_yomi_romaji || '').indexOf(lowerKeyword) !== -1 ||
        (kanji.kun_yomi || '').indexOf(lowerKeyword) !== -1 ||
        (kanji.kun_yomi_romaji || '').indexOf(lowerKeyword) !== -1
      );
    }).slice(0, 10);
  }

  return [];
});

const handleKeydown = (event) => {
  switch (event.key) {
    case 'ArrowDown':
      if (selectedIndex.value < searchedKanji.value.length - 1) {
        selectedIndex.value++
      }
      break
    case 'ArrowUp':
      if (selectedIndex.value > 0) {
        selectedIndex.value--
      }
      break
    case 'Enter':
      if (selectedIndex.value >= 0) {
        const kanji = searchedKanji.value[selectedIndex.value]
        router.push({name: 'KanjiShow', params: {character: kanji.character}})
      }
      showDropdown.value = false
      selectedIndex.value = -1
      inputField.value.select()
      break
  }
}

const onClickOutside = (event) => {
  if (!event.target.closest('.search-field') && !event.target.closest('.search-dropdown')) {
    showDropdown.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', onClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', onClickOutside)
})

watch(keyword, (newKeyword) => {
  if (newKeyword && newKeyword !== ' ') {
    showDropdown.value = true
  } else {
    showDropdown.value = false
  }
})
</script>

<style scoped lang="sass">
.selected-item
  background-color: #f4f4f5
</style>
