<template>
  <div v-if="kanji.id">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <div class="flex flex-col space-y-4 justify-between bg-amber-50 pt-8 min-h-[300px]">
        <div></div>
        <div
          :id="`kanji_${kanji.id}`"
          class="text-9xl font-kanji flex justify-center items-center">
          {{ kanji.character }}
        </div>
        <div class="p-1 flex justify-end">
          <button @click="draw">
            <i class="fa-regular fa-circle-play"></i>
            <span>Cách viết</span>
          </button>
        </div>
      </div>

      <div class="">
        <table class="table table--sm w-full kanji-detail-table">
          <tbody>
            <tr>
              <th>Bộ</th>
              <td>
                <router-link
                  :to="{ name: 'KanjiRadicalShow', params: { character: kanji.kanji_radical_character }}"
                  class="link text-lg"
                  @click="$emit('close')"
                >
                  <span class="font-vietnamese">{{ kanji.kanji_radical_name_vi }}</span>
                  <span class="ml-6 font-kanji">{{ kanji.kanji_radical_character }}</span>
                </router-link>
              </td>
            </tr>
            <tr>
              <th>Âm Hán Việt</th>
              <td class="text-2xl font-vietnamese">{{ kanji.name_vi }}</td>
            </tr>
            <tr>
              <th>Nghĩa Hán Việt</th>
              <td class="">{{ kanji.description_vi }}</td>
            </tr>
            <tr>
              <th>Âm On</th>
              <td>
                <div class="font-bold">{{ kanji.on_yomi }}
                </div>
              </td>
            </tr>
            <tr>
              <th>Âm Kun</th>
              <td>
                <div v-html="highlightKanjiReading(kanji.kun_yomi)"></div>
              </td>
            </tr>
            <tr>
              <th>Số nét</th>
              <td>{{ kanji.strokes }}</td>
            </tr>
            <tr>
              <th>Cấp độ JLPT</th>
              <td>{{ kanji.jlpt_level }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="mt-12 kanji-examples">
      <h3 class="text-2xl page-subtitle">Ví dụ cách sử dụng kanji {{ kanji.character }}</h3>

      <TextExample :examples="kanji.examples" :highlight="kanji.character" class="mt-4"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watchEffect} from "vue";
import { highlightKanjiReading } from "$/utils";
import TextExample from "&/TextExample.vue";

const props = defineProps<{
  kanji: object
}>()

function draw() {
  document.getElementById(`kanji_${props.kanji.id}`).innerText = ''
  var dmak = new Dmak(props.kanji.character, {
    uri: '/characters/',
    height: 200,
    width: 200,
    step: 0.012,
    element : `kanji_${props.kanji.id}`,
    stroke: {
      order: {
        visible: true,
        attr: {
          'font-size': 10,
        }
      },
      attr: {
        stroke: 'random'
      }
    },
  });
}

watchEffect(() => {
  if (props.kanji.id) {
    setTimeout(() => {
      draw()
    }, 1000)
  }
});
</script>

<style scoped lang="sass">
:deep(.kanji-detail-table)
  th
    color: dimgray
    font-weight: normal
    text-align: left
  td
    font-weight: normal
    font-size: 1.25rem
</style>
