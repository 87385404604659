import { defineStore } from "pinia";
import apiCall from '$/plugins/axios'

export const useWordStore = defineStore('wordStore', {
    state: () => {
        return {
            page: {
                currentPage: 1,
                totalPage: 1,
                totalCount: null
            },
            categories: [],
            category: null,
            words: [],
            word: null,
            searchedWords: [],
        }
    },

    actions: {
        async fetchCategories() {
            try {
                const res = await apiCall.get('/categories')
                this.categories = res.data
            } catch(err) {
                console.error('Failed to fetch categories:', err.message || err)
            }
        },

        async fetchCategory(slug) {
            try {
                const res = await apiCall.get(`/categories/${slug}?page=${this.page.currentPage}`)
                this.category = res.data
                this.page.totalPage = parseInt(res.headers['total-pages'])
                this.page.totalCount = parseInt(res.headers['total-count'])
            } catch(err) {
                console.error('Failed to fetch category:', err.message || err)
            }
        },

        async fetchWords() {
            try {
                const res = await apiCall.get('/words')
                this.words = res.data
            } catch(err) {
                console.error('Failed to fetch words:', err.message || err)
            }
        },

        async fetchWord(category_slug, ja) {
            try {
                const res = await apiCall.get(`/words/${category_slug}/${ja}`)
                this.word = res.data
            } catch(err) {
                console.error('Failed to fetch word:', err.message || err)
            }
        },

        async search(keyword) {
            try {
                const res = await apiCall.get(`/search/${keyword}?page=${this.page.currentPage}`)
                this.searchedWords = res.data
                this.page.totalPage = parseInt(res.headers['total-pages'])
                this.page.totalCount = parseInt(res.headers['total-count'])
            } catch(err) {
                console.error('Failed to search:', err.message || err)
            }
        },
    },

    getters: {
        categoryCount: (state) => state.categories.length,
        wordCount: (state) => state.words.length,
    }
})
