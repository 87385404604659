<template>
  <div>
    <TheBreadcrumb>
      <li>214 bộ thủ</li>
    </TheBreadcrumb>

    <div class="grid grid-cols-12 gap-8">
      <main class="col-span-12 lg:col-span-9">
        <div class="grid grid-cols-2 md:grid-cols-5 gap-6 mb-12">
          <router-link v-for="level in jlptLevels"
                       :to="{ path: `/kanji/jlpt-${level[0].toLowerCase()}` }"
                       class="px-2 py-2 rounded-lg shadow text-center hover:opacity-60 flex space-x-2 items-center"
                       :style="`background-color: ${level[1]}`"
          >
            <img src="@/images/icons/cards.png" :alt="`Kanji JLPT ${level[0]}`" class="w-1/4"/>
            <span>Kanji JLPT {{ level[0] }}</span>
          </router-link>
        </div>

        <h1 class="kanji-page-title uppercase">214 Bộ Thủ Cơ Bản</h1>

        <section v-for="(radicals, stroke) in kanjiStore.kanjiRadicals" :key="stroke" class="mt-12">
          <div class="flex space-x-2 items-center">
            <h2 :id="`bo-thu-${stroke}-net`" class="kanji-page-subtitle">Bộ thủ {{ stroke }} nét</h2>
            <div>({{ radicals.length }} bộ)</div>
          </div>
          <div class="mt-8 grid grid-cols-12 gap-8">
            <KanjiRadicalListItem
              v-for="radical in radicals" :key="radical.id" :kanji-radical="radical"
              class="col-span-12 md:col-span-6 xl:col-span-4"
            />
          </div>
        </section>
      </main>

      <aside class="hidden lg:block lg:col-span-3">
        <section class="sticky top-0 py-4 z-10 bg-gray-50">
          <AppHint class="">
            Bạn có thể tìm kiếm theo âm Hán Việt, On-yomi, Kun-yomi, Romaji
          </AppHint>
          <AppHeading class="mt-8">
            Tìm kanji
          </AppHeading>
          <KanjiSearchForm/>

          <div class="my-4">
            <AdsenseAside1/>
          </div>
        </section>

        <section class="mt-8">
          <AppHeading>Bộ thủ theo số nét</AppHeading>
          <ul class="grid grid-cols-4">
            <li v-for="(_, stroke) in kanjiStore.kanjiRadicals" :key="stroke">
              <a :href="`#bo-thu-${stroke}-net`" class="link px-2 py-1 block">
                {{ stroke }} nét
              </a>
            </li>
          </ul>
        </section>
      </aside>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useKanjiStore } from "$/stores/kanjiStore";
import { useHead } from "@unhead/vue";
import KanjiRadicalListItem from "&/KanjiRadicalListItem.vue";
import AppHeading from "&/AppHeading.vue";
import TheBreadcrumb from "&/TheBreadcrumb.vue";
import AppHint from "&/AppHint.vue";
import KanjiSearchForm from "&/KanjiSearchForm.vue";
import AdsenseAside1 from "&/AdsenseAside1.vue";
import {onMounted} from "vue";

const kanjiStore = useKanjiStore()
const jlptLevels = [
  ['N5', '#d1fae5'],
  ['N4', '#cffafe'],
  ['N3', '#ffedd5'],
  ['N2', '#fae8ff'],
  ['N1', '#ffe4e6'],
]

onMounted(() => {
  useHead({
    title: '214 Bộ Thủ Kanji Tiếng Nhật - Học Hán Tự Cơ Bản | TiengNhatIT.com',
    meta: [
      {
        name: "description",
        content: 'Khám phá 214 bộ thủ Kanji tiếng Nhật từ cơ bản đến nâng cao. Tìm hiểu ý nghĩa, cách viết và âm đọc của từng bộ thủ để học Kanji hiệu quả hơn tại TiengNhatIT.com.',
      },
    ],
  })
})
</script>
