<template>
  <div v-if="ready" class="max-w-3xl mx-auto">
    <TheBreadcrumb>
      <li>
        <router-link :to="{ name: 'KanjiRadicalIndex' }">
          214 bộ thủ
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'KanjiRadicalShow', params: { character: kanjiStore.kanjiDetail.kanji_radical_character }}">
          Bộ {{ kanjiStore.kanjiDetail.kanji_radical_name_vi }}
        </router-link>
      </li>
      <li>{{ kanjiStore.kanjiDetail.character }}</li>
    </TheBreadcrumb>

    <main class="">
      <h1 class="kanji-page-title uppercase">Chi tiết kanji {{ kanjiStore.kanjiDetail.character }}</h1>

      <div class="mt-8 mx-auto max-w-2xl bg-white shadow-lg rounded p-8 relative">
        <KanjiDetail :kanji="kanjiStore.kanjiDetail"/>
      </div>

      <div class="flex justify-between max-w-2xl mx-auto mt-6">
        <router-link class="hover:bg-opacity-90 rounded-lg flex items-center justify-center space-x-2 bg-sky-200 bg-opacity-60 px-8 py-2 min-w-[160px] lg:min-w-[240px]"
                     :to="{ name: 'KanjiShow', params: { character: kanjiStore.kanjiDetail.prev_kanji }}"
        >
          <i class="fa-solid fa-left-long"></i>
          <div class="font-kanji text-3xl">{{ kanjiStore.kanjiDetail.prev_kanji }}</div>
        </router-link>

        <router-link class="hover:bg-opacity-90 rounded-lg flex items-center justify-center space-x-2 bg-sky-200 bg-opacity-60 px-8 py-2 min-w-[160px] lg:min-w-[240px]"
                     :to="{ name: 'KanjiShow', params: { character: kanjiStore.kanjiDetail.next_kanji }}"
        >
          <div class="font-kanji text-3xl">{{ kanjiStore.kanjiDetail.next_kanji }}</div>
          <i class="fa-solid fa-right-long"></i>
        </router-link>
      </div>
    </main>
  </div>

  <AppLoading v-else/>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue";
import { useKanjiStore } from "$/stores/kanjiStore";
import { useHead } from "@unhead/vue";
import KanjiDetail from "&/KanjiDetail.vue";
import TheBreadcrumb from "&/TheBreadcrumb.vue";
import AppLoading from "&/AppLoading.vue";

const kanjiStore = useKanjiStore()
const ready = ref(false)

const props = defineProps<{
  character: string
}>()

onMounted(async () => {
  window.scrollTo(0, 0)
  await kanjiStore.fetchKanjiDetail(props.character)
  useHead({
    title: `Chi tiết kanji ${kanjiStore.kanjiDetail.character} | TiengNhatIT.com`,
  })
  ready.value = true
})
</script>
