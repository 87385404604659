<template>
  <form @submit.prevent class="">
    <div class="relative">
      <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
        </svg>
      </div>
      <input
        ref="inputField"
        v-model="keyword"
        type="search"
        placeholder="Tìm kiếm từ vựng"
        class="input-field search-field pl-8"
        @keydown="handleKeydown"
      />
    </div>

    <div class="relative search-dropdown">
      <div
        v-show="showDropdown"
        class="absolute top-0 right-0 z-30 bg-white text-black shadow-xl rounded-lg w-full lg:w-[500px] max-h-[60vh] overflow-y-scroll"
      >
        <router-link
          v-for="(word, index) in searchedWords"
          :key="index"
          :to="{ path: `/tu-vung/${word.category_slug}/${word.ja || word.en}` }"
          class="flex space-x-2 justify-between p-2 border-b border-solid border-zinc-200 hover:bg-zinc-100"
          :class="{ 'selected-item': index === selectedIndex }"
          @click="showDropdown = false"
        >
          <div>
            <strong>{{ word.ja }}</strong>
            <span class="ml-4">{{ word.ja_kana }}</span>
            <div>{{ word.en }}</div>
            <em>{{ word.vi }}</em>
          </div>
          <div class="max-w-[80px] lg:max-w-[120px]">
            <WordPreview :word="word" />
          </div>
        </router-link>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import { useWordStore } from '$/stores/wordStore'
import WordPreview from '&/WordPreview.vue'

const router = useRouter()
const wordStore = useWordStore()
const keyword = ref(null)
const inputField = ref(null)
const selectedIndex = ref(-1)

const showDropdown = ref(false)
const searchedWords = computed(() => {
  if (keyword.value) {
    const lowerKeyword = keyword.value.toLowerCase();
    return wordStore.words.filter((word) => {
      return (
        (word.ja || '').indexOf(lowerKeyword) !== -1 ||
        (word.ja_kana || '').indexOf(lowerKeyword) !== -1 ||
        (word.ja_romaji || '').toLowerCase().indexOf(lowerKeyword) !== -1 ||
        (word.en || '').toLowerCase().indexOf(lowerKeyword) !== -1 ||
        (word.vi || '').toLowerCase().indexOf(lowerKeyword) !== -1 ||
        (word.preview || '').toLowerCase().indexOf(lowerKeyword) !== -1
      )
    }).slice(0, 10)
  }
})

const handleKeydown = (event) => {
  switch (event.key) {
    case 'ArrowDown':
      if (selectedIndex.value < searchedWords.value.length - 1) {
        selectedIndex.value++
      }
      break
    case 'ArrowUp':
      if (selectedIndex.value > 0) {
        selectedIndex.value--
      }
      break
    case 'Enter':
      if (selectedIndex.value >= 0) {
        const word = searchedWords.value[selectedIndex.value]
        router.push({ path: `/tu-vung/${word.category_slug}/${word.ja || word.en}` })
      } else {
        router.push({ name: 'SearchResult', params: { keyword: keyword.value } })
      }
      showDropdown.value = false
      selectedIndex.value = -1
      inputField.value.select()
      break
  }
}

const onClickOutside = (event) => {
  if (!event.target.closest('.search-field') && !event.target.closest('.search-dropdown')) {
    showDropdown.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', onClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', onClickOutside)
})

watch(keyword, (newKeyword) => {
  if (newKeyword && newKeyword !== ' ') {
    showDropdown.value = true
  } else {
    showDropdown.value = false
  }
})
</script>

<style scoped lang="sass">
.selected-item
  background-color: #f4f4f5
</style>
