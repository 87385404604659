<template>
  <footer class="mt-12 pt-4 pb-6 bg-gray-200 border-t-2 border-t-gray-500">
    <div class="w-max mx-auto">&copy; tiengnhatit.com 2018 - {{ new Date().getFullYear() }}</div>
    <div class="w-max mx-auto mt-2">
      <i18n-t keypath="created_by.text" tag="p">
        <template #ownerLink>
          <a href="https://www.facebook.com/yuto.yasunaga.27" target="_blank" class="font-bold link">{{
            $t('created_by.owner')
          }}</a>
        </template>
      </i18n-t>
    </div>
  </footer>

  <div id="adsenseFooter" class="">
    <AdsenseFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AdsenseFooter from '&/AdsenseFooter.vue'

export default defineComponent({
  components: {
    AdsenseFooter
  }
})
</script>
