<template>
  <div v-if="page.totalPage > 1">
    <button :class="['text-xl link', { disabled: !hasPrevPage }]" :disabled="!hasPrevPage" @click="prev">
      <i class="fas fa-arrow-left" />
      {{ $t('page.prev') }}
    </button>

    <button :class="['text-xl link ml-12', { disabled: !hasNextPage }]" :disabled="!hasNextPage" @click="next">
      {{ $t('page.next') }}
      <i class="fas fa-arrow-right" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed, defineEmits } from 'vue'
import { useWordStore } from '$/stores/wordStore'

const emit = defineEmits(['change-page'])

const wordStore = useWordStore()
const page = computed(() => wordStore.page)
const hasPrevPage = computed(() => page.value.currentPage > 1)
const hasNextPage = computed(() => page.value.currentPage < page.value.totalPage)

const prev = () => {
  emit('change-page', page.value.currentPage - 1)
}
const next = () => {
  emit('change-page', page.value.currentPage + 1)
}
</script>
