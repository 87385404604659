<template>
  <div class="p-2 lg:p-4">
    <section class="my-4">
      <router-link :to="{ name: 'KanjiRadicalIndex' }" class="rounded-lg shadow flex space-x-4 p-2 items-center max-w-[300px] bg-yellow-50 hover:bg-white border-2 border-yellow-200">
        <img src="@/images/icons/flash-card.png" alt="Flashcard Kanji tiếng Nhật" class="w-[25%]"/>
        <div>
          <div class="font-bold font-vietnamese text-xl lg:text-2xl">Kanji Flashcard</div>
          <div class="text-base text-gray-600">214 bộ thủ</div>
        </div>
      </router-link>
    </section>

    <div class="my-4">
      <AdsenseMain2 />
    </div>

    <div>
      <div class="mx-auto mt-2 max-w-2xl">
        <h1 class="text-center text-3xl font-bold text-primary">Từ vựng tiếng Nhật chuyên ngành IT</h1>
        <p class="text-gray-600 mt-8 text-lg">Khám phá kho từ vựng tiếng Nhật chuyên ngành IT phong phú, được phân chia rõ ràng theo từng chủ đề. Hỗ trợ bạn nắm vững các thuật ngữ cần thiết trong công việc và học tập.</p>
        <div class="flex justify-center mt-6 text-xl">
          <div class="px-4 py-1 border bg-cyan-100 text-cyan-700 font-bold">{{ wordStore.categoryCount }} chủ đề</div>
          <div class="px-4 py-1 border bg-violet-100 text-violet-700 font-bold">{{ wordStore.wordCount }} từ vựng</div>
        </div>
      </div>

      <div class="mt-10 grid grid-cols-12 gap-6 category-list">
        <CategoryListItem
          v-for="category in wordStore.categories"
          :key="category.id"
          :category="category"
          class="col-span-12 md:col-span-6 lg:col-span-4"
        />
      </div>
    </div>

    <div class="my-4">
      <AdsenseMultiplex />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useWordStore } from '$/stores/wordStore'
import { useHead } from "@unhead/vue";
import CategoryListItem from '&/CategoryListItem.vue'
import AdsenseMain2 from '&/AdsenseMain2.vue'
import AdsenseMultiplex from '&/AdsenseMultiplex.vue'

const wordStore = useWordStore()

onMounted(async () => {
  useHead({
    title: 'Từ Vựng Tiếng Nhật IT - Học Từ Vựng IT Chuyên Ngành | TiengNhatIT.com',
    meta: [
      {
        name: "description",
        content: 'TiengNhatIT.com cung cấp kho từ vựng tiếng Nhật chuyên ngành IT phong phú, được phân loại theo từng chủ đề. ' +
          'Học từ vựng IT dễ dàng, giúp bạn thành thạo công việc trong ngành IT.'
      }
    ]
  })
})
</script>
