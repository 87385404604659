<template>
  <div v-if="ready" class="">
    <KanjiSearchForm class="bg-white p-2 sticky top-0 shadow-md block lg:hidden z-20" />
    <div class="px-4 py-8 lg:p-8">
      <router-view :key="$route.fullPath" />
    </div>
  </div>

  <AppLoading v-else class="mt-24"/>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useKanjiStore } from '$/stores/kanjiStore'
import AppLoading from "&/AppLoading.vue";
import KanjiSearchForm from "&/KanjiSearchForm.vue";

const kanjiStore = useKanjiStore()
const ready = ref(false)

onMounted(async () => {
  await kanjiStore.fetchKanji()
  await kanjiStore.fetchKanjiRadicals()
  ready.value = true
})
</script>
