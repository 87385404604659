<template>
  <aside id="sidebar" class="fixed lg:hidden top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full lg:translate-x-0" aria-label="Sidebar">
    <div class="h-full px-3 py-8 overflow-y-auto bg-gray-200 dark:bg-gray-800">
      <div class="flex flex-col space-y-2 justify-center items-center">
        <img src="@/images/logo.png" alt="Từ vựng tiếng Nhật chuyên ngành IT - tiengnhatit.com" class="w-[128px] max-w-[128px]" />
        <router-link :to="{ name: 'CategoryIndex' }" class="text-xl lg:text-2xl text-primary font-bold"> tiengnhatit.com </router-link>
      </div>

      <ul class="space-y-4 font-bold text-gray-700 mt-8">
        <li>
          <router-link :to="{ name: 'CategoryIndex' }">
            Từ vựng chuyên ngành IT
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'KanjiRadicalIndex' }">
            214 bộ thủ Kanji
          </router-link>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script setup lang="ts">
import { useWordStore } from "$/stores/wordStore";
import { useFlowbite } from "$/composables/useFlowbite";

useFlowbite(['dropdowns'])

const wordStore = useWordStore()
</script>
