<template>
  <AppModal id="kanjiDetailModal" title="Chi tiết kanji" @close="closeModal" :max-width="800">
    <KanjiDetail :kanji="kanjiDetail" @close="closeModal"/>
  </AppModal>

  <div v-if="ready">
    <TheBreadcrumb>
      <li>
        <router-link :to="{ name: 'KanjiRadicalIndex' }">
          214 bộ thủ
        </router-link>
      </li>
      <li>{{ title }}</li>
    </TheBreadcrumb>

    <main>
      <h1 class="kanji-page-title uppercase">{{ title }}</h1>

      <div class="mt-4 text-gray-600 mx-auto max-w-xl text-lg text-center">{{ description}}</div>

      <div class="text-right text-green-500 font-semibold mt-4">Chọn vào Kanji để xem chi tiết</div>

      <div class="mt-8 grid grid-cols-12 gap-8">
        <KanjiListIItem
          v-for="kanji in kanjiList" :key="kanji.id" :kanji="kanji" :show-radical="showRadical"
          @view-detail="viewDetail"
          class="col-span-12 md:col-span-6 xl:col-span-4"
        />
      </div>
    </main>
  </div>

  <AppLoading v-else/>
</template>

<script setup lang="ts">
import { useKanjiStore } from "$/stores/kanjiStore";
import {onMounted, computed, ref} from "vue";
import TheBreadcrumb from "&/TheBreadcrumb.vue";
import AppLoading from "&/AppLoading.vue";
import KanjiListIItem from "&/KanjiListIItem.vue";
import {useHead} from "@unhead/vue";
import AppModal from "&/AppModal.vue";
import KanjiDetail from "&/KanjiDetail.vue";
import {Modal} from "flowbite";

const props = defineProps<{
  character?: string
  jlpt_level?: string
}>()

const kanjiStore = useKanjiStore()
const ready = ref(false)
const kanjiList = ref([])

const title = computed(() => {
  if (props.character) return `Tổng Hợp Kanji Bộ ${kanjiStore.kanjiRadical.name_vi} ${kanjiStore.kanjiRadical.character}`
  else if (props.jlpt_level) return `Kanji JLPT ${props.jlpt_level.toUpperCase()}`
})

const description = computed(() => {
  if (props.character) return `Danh sách ${kanjiStore.kanjiRadical.kanji.length} kanji bộ ${kanjiStore.kanjiRadical.name_vi}`
  else if (props.jlpt_level) return `Danh sách ${kanjiList.value.length} kanji trong phạm vi kỳ thi năng lực Nhật ngữ JLPT ${props.jlpt_level.toUpperCase()}`
})

const showRadical = computed(() => {
  return !props.character
})

onMounted(async () => {
  if (props.character) {
    await kanjiStore.fetchKanjiRadical(props.character)
    kanjiList.value = kanjiStore.kanjiRadical.kanji
  }
  else if (props.jlpt_level) {
    kanjiList.value = await kanjiStore.kanji.filter((i) => {
      return i.jlpt_level?.toLowerCase() === props.jlpt_level
    })
  }

  useHead({
    title: `${title.value} | TiengNhatIT.com`,
    meta: [
      {
        name: "description",
        content: description.value,
      },
    ],
  })

  ready.value = true
})

const kanjiDetail = ref({})
const modal = ref(null)

const viewDetail = function (kanjiId) {
  modal.value = new Modal(document.getElementById('kanjiDetailModal'),{}, {})
  kanjiDetail.value = kanjiStore.kanji.find((i) => i.id === kanjiId)
  modal.value.show()

  // Scroll to top of the modal
  const modalContent = document.querySelector('#kanjiDetailModal .modal-body')
  if (modalContent) modalContent.scrollTop = 0
}

function closeModal() {
  modal.value.hide()
}
</script>
